<template>
  <div class="menu-top">
    <div class="top-contain">
      <div class="top">
        <img :src="logoUrl" alt="" class="logo" @click="activeClick('0')" />
        <div class="search">
          <el-input placeholder="请输入搜索内容" v-model="search">
            <img :src="searchUrl" alt="" slot="suffix" class="el-input__icon" />
          </el-input>
        </div>
        <i class="el-icon-s-operation tabulation" @click="drawer = true"></i>
      </div>
    </div>
    <div class="bottom">
      <el-menu :default-active="currentMenu" router background-color="#A40000" text-color="#fff" active-text-color="#fff">
        <el-menu-item v-for="(item, index) in navList" :key="index" :index="item.router" style="height: 50px;">
          <div class="path">{{ item.name }}</div>
        </el-menu-item>
      </el-menu>
    </div>
    <el-drawer title="导航" :visible.sync="drawer" direction="rtl" :with-header="false">
      <el-menu :default-active="currentMenu" router background-color="#fff" text-color="#A40000"
        active-text-color="#A40000">
        <el-menu-item v-for="(item, index) in navList" :key="index" :index="item.router">
          <div class="path">{{ item.name }}</div>
        </el-menu-item>
      </el-menu>
      <div class="content_list" style="margin: 20px 0;">
        <div class="officialAccoun  list_content">
          <img class="officialAccounImg" :src="officialAccountUrl" alt="" />
        </div>

        <div style="font-size: 12px; color: #8f8f8f; ">扫码关注公众号</div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import logo from '/src/assets/logo.png'
import search from '/src/assets/search.png'
import officialAccount from '/src/assets/officialAccount.jpg'

export default {
  data() {
    return {
      logoUrl: logo,
      searchUrl: search,
      search: '',
      navList: [
        {
          id: 0,
          name: '首页',
          router: '/Home',
        },
        {
          id: 1,
          name: '概况',
          router: '/GeneralSituation',
        },
        {
          id: 2,
          name: '修复工艺',
          router: '/RepairProcess',
        },
        {
          id: 3,
          name: '文物复仿制',
          router: '/CulturalRelicsImitation',
        },
        {
          id: 4,
          name: '预防性保护',
          router: '/PreventiveProtection',
        },
        {
          id: 5,
          name: '修复材料',
          router: '/RepairMaterials',
        },
        {
          id: 6,
          name: '专家论证会',
          router: '/ExpertDemonstration',
        },
        {
          id: 7,
          name: '联系我们',
          router: '/ContactUs',
        },
      ],
      currentMenu: '/Home',
      drawer: false,
      officialAccountUrl: officialAccount,
    }
  },
  watch: {
    $route(to, from) {
      this.currentMenu = '/' + to.name
      this.drawer = false
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key)
    },
    activeClick(index) {
      this.$router.push({
        name: 'Home',
      })
    },
  },
}
</script>

<style scoped lang="scss">
@media screen and (min-width: 921px) {
  .menu-top {
    height: 140px;
  }


  .logo {
    margin-right: 9px;
    height: 50px;
    width: auto;
  }

  .tabulation {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .menu-top {
    height: 140px;
  }

  .logo {
    margin-right: 9px;
    height: 50px;
    width: auto;
  }

  .tabulation {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .menu-top {
    height: 70px;
  }

  .search {
    display: none;
  }

  .logo {
    height: 40px;
    width: auto;
  }

  .bottom {
    display: none;
    height: 0;
  }

  .tabulation {
    position: absolute;
    right: 20px;
    font-size: 20px;
  }

  .content_list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .list_content {
      margin-bottom: 12px;
      z-index: 99;
      cursor: pointer;

      &:hover {
        color: #fff;
        opacity: 0.8;
      }
    }

    .officialAccoun {
      width: 110px;
      height: 110px;
      border: 1px solid #dad7d7;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .officialAccounImg {
      width: 100px;
      height: 100px;
    }
  }
}

.menu-top {
  // height: 190px;
  width: 100%;

  .top-contain {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F9F7F3;
  }

  .top {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    background-color: #F9F7F3;

    .search {
      width: 260px;

      img {
        width: 42px !important;
        height: 36px !important;
        margin-right: -5px;
      }
    }
  }

  .bottom {
    height: 50px;
    background-color: #a40000;
    width: 100%;

    ul {
      margin-left: 15%;
      list-style: none;
      display: flex;
      justify-content: space-between;
      width: 62.5%;
      color: #989898;

      li {
        cursor: pointer;
      }

      .path {
        height: 50px !important;
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0 25px;
      }
    }
  }
}

::v-deep .el-input__inner {
  height: 36px !important;
  border-radius: 8px;
  border: 1px solid #a40000;
  color: #a40000;
  padding: 0 60px 0 15px !important;
  font-size: 14px;
  background-color: #F9F7F3;

  &::placeholder {
    color: #a40000;
  }

  &::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    color: #a40000;
  }

  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 适配火狐 */
    color: #a40000;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ 适配火狐 */
    color: #a40000;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10+  适配ie*/
    color: #a40000;
  }
}

.el-menu {
  border-right: none;
}

.is-active {
  background: #fff !important;
  color: #a40000 !important;
}
</style>
