// 大型项目使用此模式，module模式，小型项目使用普通的store模式即可，见文件夹下index-back.js

import Vue from 'vue'
import Vuex from 'vuex'

// 模块
import Menu from './modules/menu/index'//菜单
// import User from './modules/user/index'//用户信息

Vue.use(Vuex)

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		Menu,
		// User
	}
})
