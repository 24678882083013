import Vue from 'vue'
import axios from 'axios'
import { Loading, Message } from 'element-ui';
import router from '@/router/index'
//Vue.use(axios)
Vue.prototype.axios = axios

let loadingInstance
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
	// 在发送请求之前做些什么
	loadingInstance = Loading.service({ background: 'rgba(0,0,0,.6)' });
	return config;
}, function (error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
	//在这里你可以判断后台返回数据携带的请求码
	if (response.status === 200) {
		loadingInstance.close();
		return response.data
	} else {
		// 非200请求报错
		throw Error(response.data.msg || '服务异常')
	}
}, function (error) {
	// 对响应错误做点什么
	return Promise.reject(error);
});
