<template>
  <div class="container">
    <el-header>
      <NavBar></NavBar>
    </el-header>
    <router-view></router-view>
    <pageFooter />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import SideBar from './components/SideBar.vue'
import pageFooter from '/src/components/pageFooter'

export default {
  components: {
    NavBar,
    SideBar,
    pageFooter,
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'margin:0; background-color: #fff;')
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-header {
  position: fixed;
  top: 0;
  background-color: #fff;
  color: #333;
  text-align: center;
  width: 100%;
  padding: 0 !important;
  z-index: 9999;
}
</style>
