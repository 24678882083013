<template>
	<div class="menu-contain">
		<el-menu :collapse="isCollapse" :default-active="$route.path" class="el-menu-vertical-demo" @open="handleOpen"
			@close="handleClose" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
			<el-submenu index="1">
				<template slot="title">
					<i class="el-icon-user-solid"></i>
					<span>用户管理</span>
				</template>
				<el-menu-item index="/user/list">用户列表</el-menu-item><strong></strong>
				<el-menu-item index="/user/group">用户角色</el-menu-item><strong></strong>
			</el-submenu>
			<el-submenu index="2">
				<template slot="title">
					<i class="el-icon-s-order"></i>
					<span>新闻管理</span>
				</template>
				<el-menu-item index="/article/list">新闻列表</el-menu-item><strong></strong>
				<el-menu-item index="/article/group">新闻分类</el-menu-item><strong></strong>
			</el-submenu>
			<el-menu-item index="/user/edit">
				<i class="el-icon-menu"></i>
				<span slot="title">账户设置</span>
			</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	export default {
		computed: { //简写方法
			handdle() { //本地的计算属性

			},
			// store的计算属性
			...mapState('Menu',['isCollapse']) //这样简写的前提是isCollapse与store里面的名称一致;与下面写法结果一样
		},
		methods: {
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			}
		}
	}
</script>

<style>
	.menu-contain {
		background-color: rgb(84, 92, 100);
		height: calc(100vh - 60px);
		overflow-y: auto;
	}
</style>
