import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const state={
	// 折叠菜单
	isCollapse: false,
};

export default{
	namespaced:true,
	state,
	mutations,
	actions,
	getters
}