<template>
	<el-container>
		<el-header>
			<NavBar></NavBar>
		</el-header>
		<el-container>
			<el-aside width="200px">
				<SideBar></SideBar>
			</el-aside>
			<el-main>
				<router-view></router-view>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	import NavBar from './NavBar.vue';
	import SideBar from './SideBar.vue';

	export default {
		components: {
			NavBar,
			SideBar,
		}
	}
</script>

<style scoped lang="scss">
	.el-header {
		background-color: #333;
		color: #333;
		text-align: center;
		height: 60px;
	}
</style>
