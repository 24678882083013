<template>
  <div class="pageFooter">
    <div class="footer_content1">
      <div class="content">
        <div v-for="(item, index) in navData" :key="index" class="content_list">
          <div class="title" @click="changeHandle(item, index)">{{ item.type }}</div>
          <div v-for="(ele, i) in item.children" :key="i" @click="changeHandle(ele)" class="list_content">
            {{ ele.name }}
          </div>
        </div>
        <div class="content_list">
          <div class="officialAccoun  list_content">
            <img class="officialAccounImg" :src="officialAccountUrl" alt="" />
          </div>

          <div>扫码关注公众号</div>
        </div>
      </div>
      <div class="friendly_link">
        <div class="title">友情链接</div>
        <div class="link_right">
          <div v-for="(item, index) in linkData" :key="index" class="name">
            <a :href="item.link">{{ item.name }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_content2">
      <div class="content">
        <div v-for="(item, index) in navData" :key="index" class="content_list">
          <div class="title" @click="changeHandle(item, index)">{{ item.type }}</div>
          <div v-for="(ele, i) in item.children" :key="i" @click="changeHandle(ele)" class="list_content">
            {{ ele.name }}
          </div>
        </div>
      </div>
      <div class="friendly_link">
        <div class="title">友情链接</div>
        <div class="link_right">
          <div v-for="(item, index) in linkData" :key="index" class="name">
            <a :href="item.link">{{ item.name }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="copy1">
      <div class="copyright1">
        <div>
          公司地址：山东省济南市高新区港兴三路1003号 联系人：颜冬 15863162867 薛斌 18615551013
        </div>
        <div class="copyright_bottom">
          山东文保文物修复技术有限公司 | 版权所有
          <a href="https://beian.miit.gov.cn" style="color: #8f8f8f; text-decoration: none">
            鲁ICP备2023010188号
          </a>
        </div>
      </div>
    </div>

    <div class="copy2">
      <div class="copyright2">
        <div>
          公司地址：山东省济南市高新区港兴三路1003号
        </div>
        <div>
          联系人：颜冬 15863162867 薛斌 18615551013
        </div>
        <div class="copyright_bottom">
          山东文保文物修复技术有限公司 | 版权所有
          <div>
            <a href="https://beian.miit.gov.cn" style="color: #8f8f8f; text-decoration: none">
              鲁ICP备2023010188号
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import officialAccount from '/src/assets/officialAccount.jpg'
export default {
  name: 'pageFooter',
  data() {
    return {
      officialAccountUrl: officialAccount,
      navData: [
        {
          type: '首页',
          children: [],
          routerName: 'Home',
        },
        {
          type: '概况',
          routerName: 'GeneralSituation',
          children: [
            {
              name: '关于文保',
              routerName: 'GeneralSituation',
            },
            {
              name: '公司内景',
              routerName: 'GeneralSituation',
            },
            {
              name: '相关资源',
              routerName: 'GeneralSituation',
            },
            {
              name: '修复厂区',
              routerName: 'GeneralSituation',
            },
          ],
        },
        {
          type: '修复工艺',
          routerName: 'RepairProcess',
          children: [
            {
              name: '金属文物保护修复',
              routerName: 'refresh',
              index: 0,
            },
            {
              name: '陶瓷文物保护修复',
              routerName: 'refresh',
              index: 1,
            },
            {
              name: '书画文物保护修复',
              routerName: 'refresh',
              index: 2,
            },
            {
              name: '古籍文物保护修复',
              routerName: 'refresh',
              index: 3,
            },
          ],
        },
        {
          type: '联系我们',
          children: [
            {
              name: '电话',
              routerName: 'ContactUs',
            },
            {
              name: '公司地址',
              routerName: 'ContactUs',
            },
          ],
        },
      ],
      linkData: [
        {
          name: '山东省文物局',
          link: 'http://whhly.shandong.gov.cn',
        },
        {
          name: '山东省博物馆',
          link: 'http://www.sdmuseum.com',
        },
        {
          name: '青岛市博物馆',
          link: 'https://www.qingdaomuseum.cn',
        },
        {
          name: '威海市博物馆',
          link: 'http://www.whmuseum.net',
        },
        {
          name: '沂水县博物馆',
          link:
            'https://baike.baidu.com/item/%E6%B2%82%E6%B0%B4%E5%8E%BF%E5%8D%9A%E7%89%A9%E9%A6%86/9831657?fr=aladdin',
        },
        {
          name: '宁夏回族自治区博物馆',
          link: 'http://www.nxbwg.com',
        },
        {
          name: '大同市博物馆',
          link: 'https://www.datongmuseum.com',
        },
        {
          name: '随州市博物馆',
          link: 'https://www.szbwg.net',
        },
        {
          name: '贵州省博物馆',
          link: 'http://www.gzmuseum.com',
        },
        {
          name: '东营市博物馆',
          link: 'http://www.dymuseum.cn',
        },
      ],
    }
  },
  methods: {
    changeHandle(ele) {
      this.$router.push({
        name: ele.routerName,
        params: { index: ele.index },
      })
      // .catch((data) => {})
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 921px) {
  .copy1 {
    background: rgba(0, 0, 0, .14);
    border-top: 1px solid #515151
  }

  .footer_content2 {
    display: none;
  }

  .copyright2 {
    display: none;
  }

  .copy2 {
    display: none;
  }

  .content {
    padding: 40px 0 0 21.6%;
    width: 54.5%;
  }

  .friendly_link {
    padding: 32px 0 0 21.6%;

    .title {
      font-size: 20px;
    }

    .link_right {
      width: 60%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .footer_content2 {
    display: none;
  }

  .copy2 {
    display: none;
  }

  .content {
    padding: 40px 0 0 5.6%;
    width: 80%;
  }

  .friendly_link {
    padding: 32px 0 0 5.6%;

    .title {
      font-size: 16px;
    }

    .link_right {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer_content1 {
    display: none;
  }

  .copy1 {
    display: none;
  }

  .footer_content2 {
    .content {
      display: block;
    }
  }
}

.pageFooter {
  width: 100%;
  height: auto;
  background-color: #2E2121;
  color: #fff;
  clear: both;

  .footer_content1 {
    height: 320px;
    margin: 0 60px;

    .content {
      display: flex;
      justify-content: space-between;
      color: #8f8f8f;
      font-size: 14px;

      .content_list {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          color: #dfdfdf;
          font-size: 20px;
          margin-bottom: 24px;
          cursor: pointer;

          &:hover {
            color: #fff;
            opacity: 0.8;
          }
        }

        .list_content {
          margin-bottom: 12px;
          z-index: 99;
          cursor: pointer;

          &:hover {
            color: #fff;
            opacity: 0.8;
          }
        }

        .officialAccoun {
          width: 116px;
          height: 116px;
          border: 1px solid #707070;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .officialAccounImg {
          width: 100px;
          height: 100px;
        }
      }
    }

    .friendly_link {
      display: flex;
      align-items: flex-start;
      height: 50px;

      .title {
        color: #dfdfdf;
        margin-right: 18px;
        width: 80px;
      }

      .link_right {
        display: flex;
        flex-wrap: wrap;

        .name {
          margin: 0 0 10px 17px;
          font-size: 14px;
          z-index: 99;

          a {
            text-decoration-line: none;
            color: #8f8f8f;
          }
        }
      }
    }
  }

  .copyright1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 0 0;
    color: #8f8f8f;
    font-size: 14px;

    .copyright_bottom {
      padding: 10px 0 20px 0;
    }
  }

  .copyright2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #8f8f8f;
    font-size: 12px;

    .copyright_bottom {
      padding: 10px 0 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .footer_content2 {
    margin: 0 20px;

    .content {
      padding: 40px 0 0 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: #8f8f8f;
      font-size: 12px;

      .content_list {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          color: #dfdfdf;
          font-size: 14px;
          margin-bottom: 24px;
          cursor: pointer;

          &:hover {
            color: #fff;
            opacity: 0.8;
          }
        }
      }
    }

    .friendly_link {
      padding: 20px 0 0 0;
      display: flex;
      align-items: flex-start;
      // height: 50px;

      .title {
        color: #dfdfdf;
        font-size: 14px;
        margin-right: 18px;
        width: 80px;
      }

      .link_right {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .name {
          margin: 0 0 10px 17px;
          font-size: 12px;
          z-index: 99;

          a {
            text-decoration-line: none;
            color: #8f8f8f;
          }
        }
      }
    }
  }
}
</style>
